import React, { useContext, useEffect, useState } from "react";
import { Button, Checkbox, Dropdown, message, Progress, Tag, Tree } from "antd";
import { FieldDataNode } from "rc-tree/lib/interface";
import styles from "./StoreyList.module.less";
import { LayoutContext } from "../TianhuaConsistencyViewer";
import { Space } from "../../../interfaces";
import {
  selectSpaces,
  setActiveSpaceId,
  setCheckSpaceId,
  fetchElementsBySpaceId,
  fetchGeomsBySpaceId,
} from "../../tuzhi-vis/tuzhiVisSlice";
import {
  setProjectId,
  setElevation,
  selectElevation,
  setTuZhiSpaceId
} from "../consistencyViewerSlice"
import { fillElementsCache } from '../../tuzhi-vis/tuzhiVisCache';
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import * as FlexLayout from "flexlayout-react";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StoreyListProps {}

type StoreyTreeDataNode = FieldDataNode<{
  key: string;
  title: string;
  spaceId: number;
  elevation: number;
}>;

function StoreyList(props: StoreyListProps) {
  const spaces = useAppSelector(selectSpaces);
  const dispatch = useAppDispatch();

  const layoutContext = useContext(LayoutContext);

  const [spaceTree, setSpaceTree] = useState<StoreyTreeDataNode[]>([]);
  const [expandKeys, setExpandKeys] = useState<React.Key[]>([]);

  const [showUnknown, setShowUnknown] = useState(false);

  useEffect(() => {
    // 通过线性目录构造目录树
    const treeRoot: StoreyTreeDataNode = {
      title: "项目根目录",
      key: "root",
      spaceId: -1,
      children: [],
      elevation: -1,
    };
    const expandKeys: string[] = ["root"];
    spaces.forEach((space: Space) => {
      if (space.name.includes("未知") && !showUnknown) return;
      if(space.elevation == null){
        return;
      }
      if (space.granularity != "MERGED"){ // 只显示单体融合空间
        return;
      }
      const path = [space.treePath[0], `${space.elevation}层图纸空间`]; // todo name是否和tree合并
      let currentNode = treeRoot;
      let currentKey = "root";
      for (let i = 0; i < path.length; i++) {
        currentKey += "/" + path[i];
        if (!currentNode.children) {
          currentNode.children = [];
        }
        let node = currentNode.children.find((n) => n.key === currentKey);
        if (!node) {
          node = {
            title: path[i],
            key: currentKey,
            spaceId: space.spaceId,
            elevation: parseInt(space.elevation),
            children: [],
          };
          currentNode.children.push(node);
          expandKeys.push(currentKey);
        }
        // @ts-ignore todo
        currentNode = node;
      }
    });
    setSpaceTree(() => [treeRoot]);
    setExpandKeys(() => expandKeys);
  }, [spaces, showUnknown]);

  const onExpand = (expandedKeysValue: React.Key[]) => {
    setExpandKeys(expandedKeysValue);
  };

  const onPreview = (spaceId: number, elevation: number) => {
    dispatch(setElevation(elevation));
    // 新建tab页或跳转tab页，打开当前资源
    const tabId = `pdf-viewer`;
    dispatch(setActiveSpaceId(spaceId));
    dispatch(setTuZhiSpaceId(spaceId));
    if (layoutContext.model.getNodeById(tabId)) {
      //   layoutContext.model.doAction(
      //     FlexLayout.Actions.selectTab(tabId),
      //   );
      // 切换到图纸展示，并且更新geoms和elements否则会不显示语义图层
      layoutContext.model.doAction(
        FlexLayout.Actions.updateNodeAttributes(tabId, {
          config: { spaceId: spaceId },
        })
      );
      layoutContext.model.doAction(
        FlexLayout.Actions.renameTab(tabId, `${elevation}层图纸空间`)
      );
      dispatch(fetchGeomsBySpaceId(spaceId));
      dispatch(fetchElementsBySpaceId(spaceId));
      fillElementsCache(spaceId);
    } else {
      layoutContext.model.doAction(
        FlexLayout.Actions.addNode(
          {
            id: tabId,
            type: "tab",
            component: "union-viewer",
            name: `[空间${spaceId}]${
              spaces.find((s) => s.spaceId === spaceId)?.name
            }`,
            config: { spaceId: spaceId },
          },
          "pdf-viewer-tab",
          FlexLayout.DockLocation.CENTER,
          -1,
          true
        )
      );
    }
  };

  return (
    <div className={styles.panelWrapper}>
      {/*<div>*/}
      {/*  空间树两个根节点，一个是空间层，一个是对应原始图纸最小识别单元*/}
      {/*</div>*/}
      <Checkbox
        value={showUnknown}
        onChange={(e) => {
          setShowUnknown(!showUnknown);
        }}
      >
        未知
      </Checkbox>
      <div className={styles.treeWrapper}>
        {/*<div>搜索文件、清空按钮</div>*/}
        <Tree.DirectoryTree
          showLine
          defaultExpandAll
          onExpand={onExpand}
          expandedKeys={expandKeys}
          treeData={spaceTree}
          titleRender={(data) => {
            if (typeof data.title === "string")
              return (
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "preview",
                        label: (
                          <div
                            onClick={(e) => {
                              e.preventDefault();
                              onPreview(data.spaceId, data.elevation);
                            }}
                          >
                            预览空间
                          </div>
                        ),
                      },
                    ],
                    style: { minWidth: 100, textAlign: "center" },
                  }}
                  trigger={["contextMenu"]}
                >
                  <div className={styles.treeItem}>
                    <div className={styles.name}>
                      {data.spaceId && data?.children?.length === 0 ? (
                        <Tag>{data.spaceId.toString()}</Tag>
                      ) : (
                        ""
                      )}
                      {data.title}
                    </div>
                  </div>
                </Dropdown>
              );
          }}
        />
      </div>
    </div>
  );
}

export default StoreyList;
