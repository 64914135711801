import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { API_BASE_URL } from '../../config';
import { store } from '../../app/store';
import { Asset } from '../../interfaces';

class ConsistencyViewerService {
  private token: string | undefined;
  private client: AxiosInstance | undefined;
  init() {
    const curStore = store.getState();
    this.token = curStore?.auth?.token;

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: ''
    };
    if (this.token) {
      headers.Authorization = `Bearer ${this.token}`;
    }

    this.client = axios.create({
      baseURL: `${API_BASE_URL}`,
      headers: headers,
    });
    return this.client;
  }


  /**
   * 获取空间的IFC文件
   */
  async getConsistencyIFC(projectId: number) {
    return this.init().get(`/consistency/tian_hua/ifc/${projectId}`, {
      responseType: 'blob',
    });
  }
  /**
   * 修改项目对应的图智项目ID
   */
  async modifyConsistenyTuzhiId(projectId: number, id: number) {
    return this.init().post(`/consistency/tian_hua/tuzhi_id/${projectId}`,{
      id
    });
  }
  
  /**
   * 获取项目的结果
   */
  async getConsistenyTuzhiId(projectId: number) {
    return this.init().get(`/consistency/tian_hua/tuzhi_id/${projectId}`);
  }
  async getConsistencyResult(projectId: number) {
    return this.init().get(`/consistency/tian_hua/result_list/${projectId}`);
  }
  async getConsistencyAsset(projectId: number) {
    return this.init().get(`/consistency/tian_hua/asset_list/${projectId}`);
  }
  async executeRoomComparison(projectId: number) {
    return this.init().get(`/consistency/tian_hua/room_comparison/${projectId}`);
  }
  async executeMemberComparison(projectId: number) {
    return this.init().get(`/consistency/tian_hua/member_comparison/${projectId}`);
  }
  async getComparison(projectId: number) {
    return this.init().get(`/consistency/tian_hua/comparison_result/${projectId}`);
  }
}

export default new ConsistencyViewerService();
