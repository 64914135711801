import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { logout, selectToken, selectUser } from './authSlice';

/**
 * 前端提前验证登录token是否有效
 */
function getTokenStatus(token: string) {
  try {
    const time = JSON.parse(atob(token.split('.')[1])).exp;
    if (time * 1000 >= Date.now() - 60 * 1000) {
      return 'valid';
    } else {
      return 'expired';
    }
  } catch (e) {
    return 'error';
  }
}

interface AuthRouteProps {
  type: string;
  outlet: JSX.Element;
  limitedRole?: string; // 新增的可选属性
}

function AuthRoute(props: AuthRouteProps) {
  const {type, outlet, limitedRole} = props;
  const token = useAppSelector(selectToken);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  // 当为235测试版时，暂时绕过登录验证
  if (
    [
      'temp',
      // '166.111.80.235', 's.thu.la', 'dev.fp.thu.la',
      // 'localhost', '127.0.0.1'
    ].includes(window.location.hostname)
  ) {
    return outlet;
  }

  const tokenStatus = getTokenStatus(token);
  if (type === 'private' && tokenStatus === 'expired') {
    alert('用户登录已过期，请重新登录');
    dispatch(logout());
    return <Navigate to="/login" />;
  } else if (type === 'private' && tokenStatus === 'error') {
    alert('用户未登录，将为您跳转登录界面');
    return <Navigate to="/login"/>;
  }

  if (limitedRole && user.role === limitedRole) {
    alert('您没有权限访问此页面');
    return <Navigate to="/unauthorized" />;
  }
  return outlet;
}

AuthRoute.propTypes = {
  logout: PropTypes.func,
  token: PropTypes.string,
  type: PropTypes.oneOf(['guest', 'private'])
};

export default AuthRoute;