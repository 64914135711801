import React from 'react';
import { NodeRendererProps, Tree } from 'react-arborist';
import { useResizeDetector } from 'react-resize-detector';
import { CheckCircleOutlined, CloseCircleOutlined, UnorderedListOutlined } from '@ant-design/icons';

import styles from './TuzhiTree.module.less';

type NodeProp = {
  id: string;
  name: string;
  totalCount: number;
  totalErrorCount: number;
};

function Node({ node, style }: NodeRendererProps<NodeProp>) {
  return (
    <div className={styles.node} style={style} onClick={() => {
      if (node.isLeaf){
        const target = document.getElementById(`check-report-${node.data.id}`);
        if (target) {
          target.scrollIntoView({block: 'start'});
        }
      }else {
        node.toggle();
      }
    }}>
      {
        node.isLeaf ?
          '' : '🗀'
      }
        {node.isLeaf ?
          node.data.name :
          <span>
            {node.data.name}{'  '}
            <UnorderedListOutlined title="共审查条文数量"  />
            {node.data.totalCount}{' ( '}
            <CloseCircleOutlined title="未通过条文数量" />
            {node.data.totalErrorCount} {' )'}
          </span>
        }

    </div>
  );
}

interface TuzhiTreeProp<T> {
  data: any;
}

function TuzhiTree(props: TuzhiTreeProp<NodeProp>) {
  const {data} = props;

  const { width, height, ref } = useResizeDetector({
    // refreshMode: 'throttle',
    // refreshRate: 500
  });

  return (
    <div ref={ref} style={{ width: "100%", height: "100%" }}>
      <Tree
        disableDrag
        indent={16}
        data={data}
        width={width}
        height={height}
      >
        {Node}
      </Tree>
    </div>
  );
}

export default TuzhiTree;