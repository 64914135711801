import axios, { AxiosInstance } from 'axios';
import { API_BASE_URL } from '../../config';
import { store } from '../../app/store';

class ProjectListService {
  private token: string | undefined;
  private client: AxiosInstance | undefined;

  init() {
    const curStore = store.getState();
    this.token = curStore?.auth?.token;

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: ''
    };
    if (this.token) {
      headers.Authorization = `Bearer ${this.token}`;
    }

    this.client = axios.create({
      baseURL: `${API_BASE_URL}/project`,
      headers: headers,
    });
    return this.client;
  }

  async getProjectList(page: number, pageSize: number, listType: string) {
    return this.init().get('/', {
      params: {
        page,
        page_size: pageSize,
        listType: listType,
      },
    });
  }

  async createProject(name: string) {
    return this.init().post('/', {
      name
    });
  }

  async deleteProject(projectId: number, realDelete = false) {
    return this.init().delete(`/${projectId}`, {
      params: {
        realDelete
      }
    });
  }
}

export default new ProjectListService();
