import { Checkbox, Col, Row } from 'antd';
import React, { Dispatch, SetStateAction } from 'react';
import { CheckboxValueType } from 'antd/es/checkbox/Group';

const errorTypeOptions = [
  { value: '1构件缺失', label: '1构件缺失' },
  { value: '2属性缺失', label: '2属性缺失' },
  { value: '3构件错误', label: '3构件错误' },
  { value: '4硬性错误', label: '4硬性错误' },
  { value: '5不适用', label: '5不适用' },
];

const passOptions = [
  { value: '未通过', label: '未通过' },
  { value: '通过', label: '通过' },
  { value: '设计说明', label: '设计说明' },
  { value: '只看被条文关联影响的条文', label: '只看被条文关联影响的条文' },
];

type TypeMap = {
  [key: string]: boolean
};

interface ResultTypeListProps {
  resultTypeMap: TypeMap;
  setResultTypeMap: Dispatch<SetStateAction<TypeMap>>;
}

function ResultTypeList(props: ResultTypeListProps) {
  const {resultTypeMap, setResultTypeMap} = props;

  const onChange = (checkedValues: CheckboxValueType[]) => {
    setResultTypeMap({
      // @ts-ignore
      ...checkedValues.reduce((o, cur) => (o[cur] = true, o), {} as TypeMap)
    })
  };

  return (
    <div>
      <Checkbox.Group defaultValue={['3构件错误', '4硬性错误', '未通过']} onChange={onChange}>
        <Row>
          <Col span={24}>
            {
              errorTypeOptions.map(o => (
                <Checkbox key={o.value} value={o.value}>{o.label}</Checkbox>
              ))
            }
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {
              passOptions.map(o => (
                <Checkbox key={o.value} value={o.value}>{o.label}</Checkbox>
              ))
            }
          </Col>
        </Row>
      </Checkbox.Group>

    </div>
  )
}

export default ResultTypeList;