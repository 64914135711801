import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import counterReducer from '../modules/counter/counterSlice';
import authReducer from '../modules/auth/authSlice';
import visReducer from '../modules/tuzhi-vis/tuzhiVisSlice';
import modifierReducer from '../modules/tuzhi-vis/union-viewer/modifier-layer/modifierSlice';
import consistencyReducer from '../modules/consistency-viewer/consistencyViewerSlice';
import appReducer from '..//appSlice';
import storage from 'redux-persist/lib/storage';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';

const persistConfig = {
  key: 'auth',
  storage,
}
const persistedAuthReducer = persistReducer(persistConfig, authReducer);

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: persistedAuthReducer,
    vis: visReducer,
    meta: appReducer,
    modifier: modifierReducer,
    consistency: consistencyReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    // 忽略redux-persist相关action序列化检查
    serializableCheck: {
      ignoredActions: [
        FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER,
      ],
    },
  }).concat([
  ]),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
