import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, Dropdown, message, Popover, Tag, Tree } from 'antd';
import { FieldDataNode } from 'rc-tree/lib/interface';
import styles from './SpaceTree.module.less';
import { LayoutContext } from '../TuzhiVis';
import { Space } from '../../../interfaces';
import { selectSpaces, setActiveSpaceId, setCheckSpaceId } from '../tuzhiVisSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import * as FlexLayout from 'flexlayout-react';
import { saveAs } from 'file-saver';
import TuzhiVisService from '../TuzhiVis.service';
import { InfoCircleOutlined } from '@ant-design/icons';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SpaceTreeProps {
}

type SpaceTreeDataNode = FieldDataNode<{
  key: string;
  title: string;
  spaceId: number;
}>;
const semanticTypeMapping = {
  UNKNOWN: '未知类型',
  DES_SPECIFICATION: '设计说明',
  BUILDING_FLOOR_PLAN: '建筑平面图',
  FIRE_ALARM_PLAN: '自动报警平面图',
  EXTINGUISHER_PLAN: '灭火器平面布置图',
  ILLUMINATION_PLAN: '应急照明和疏散平面图',
  FIRE_WATER_SUPPLY_PLAN: '消防给水平面图',
  SMOKE_CONTROL_PLAN: '防排烟平面图',
  FIRE_COMPARTMENT_PLAN: '防火分区示意图'
};
function SpaceTree(props: SpaceTreeProps) {
  const spaces = useAppSelector(selectSpaces);
  const dispatch = useAppDispatch();

  const layoutContext = useContext(LayoutContext);

  const [spaceTree, setSpaceTree] = useState<SpaceTreeDataNode[]>([]);
  const [expandKeys, setExpandKeys] = useState<React.Key[]>([]);

  const [showUnknown, setShowUnknown] = useState(false);
  const [stats, setStats] = useState<{[key: string]: number}>({});
  useEffect(() => {
    // 通过线性目录构造目录树
    const treeRoot: SpaceTreeDataNode = {
      title: '项目根目录',
      key: 'root',
      spaceId: -1,
      children: [],
    };
    const stats: { [key: string]: number } = {};
    const expandKeys: string[] = ['root'];
    spaces.forEach((space: Space) => {
      const type = space.semanticType;
      stats[type] = (stats[type] || 0) + 1;
      if (space.name.includes('未知') && !showUnknown)
        return;
      const path = [...space.treePath, `${space.name}`]; // todo name是否和tree合并
      let currentNode = treeRoot;
      let currentKey = 'root';
      for (let i = 0; i < path.length; i++) {
        currentKey += '/' + path[i];
        if (!currentNode.children) {
          currentNode.children = [];
        }
        let node = currentNode.children.find(n => n.key === currentKey);
        if (!node) {
          node = {
            title: path[i],
            key: currentKey,
            spaceId: space.spaceId,
            children: [],
          };
          if (process.env.REACT_APP_THEME !== 'szzj' || node.title !== '单体多层融合空间') {
            currentNode.children.push(node);
            expandKeys.push(currentKey);
          }
        }
        // @ts-ignore todo
        currentNode = node;
      }
    });
    setSpaceTree(() => [treeRoot]);
    setExpandKeys(() => expandKeys);
    setStats(stats);
  }, [spaces, showUnknown]);

  const onExpand = (expandedKeysValue: React.Key[]) => {
    setExpandKeys(expandedKeysValue);
  };


  const onPreview = (spaceId: number) => {
    // 新建tab页或跳转tab页，打开当前资源
    const tabId = `space-${spaceId}`;
    dispatch(setActiveSpaceId(spaceId));
    if (layoutContext.model.getNodeById(tabId)) {
      layoutContext.model.doAction(
        FlexLayout.Actions.selectTab(tabId),
      );
    } else {
      layoutContext.model.doAction(FlexLayout.Actions.addNode(
        {
          id: tabId, type: 'tab',
          component: 'union-viewer',
          name: `[空间${spaceId}]${spaces.find(s => s.spaceId === spaceId)?.name}`,
          config: {spaceId: spaceId},
        },
        'viewer-pane', FlexLayout.DockLocation.CENTER, -1, true,
      ));
    }
  };

  const onCheck = (spaceId: number) => {
    dispatch(setCheckSpaceId(spaceId));
  };
  const generateStatsContent = (stats: { [key: string]: number }) => {
  return (
    <div>
      {Object.entries(stats).map(([type, count]) => {
        const typeName = semanticTypeMapping[type as keyof typeof semanticTypeMapping];
        if (!typeName) return null; 
        return (
          <div style={{ textAlign: 'left' }} key={type}>
            {typeName}: {count}
          </div>
        );
      })}
    </div>
  );
}

  return (
    <div className={styles.panelWrapper}>
      {/*<div>*/}
      {/*  空间树两个根节点，一个是空间层，一个是对应原始图纸最小识别单元*/}
      {/*</div>*/}
      <div>
        <Checkbox
          value={showUnknown}
          onChange={e => {
            setShowUnknown(!showUnknown);
          }}>未知</Checkbox>
        <Popover content={generateStatsContent(stats)} title="文件类型统计">
          <InfoCircleOutlined />
        </Popover>
      </div>
      <div className={styles.treeWrapper}>
        {/*<div>搜索文件、清空按钮</div>*/}
        <Tree.DirectoryTree
          showLine
          defaultExpandAll
          onExpand={onExpand}
          expandedKeys={expandKeys}
          treeData={spaceTree}
          titleRender={(data) => {
            if (typeof data.title === 'string')
              return (
                <Dropdown menu={{
                  items: [
                    ...[
                      {
                        key: 'preview',
                        label: (
                          <div
                            onClick={e => {
                              e.preventDefault();
                              onPreview(data.spaceId);
                            }}
                          >
                            预览空间
                          </div>
                        ),
                      },
                    ],
                    ...process.env.REACT_APP_THEME !== 'szzj' ?
                      [
                        {
                          key: 'check',
                          label: (
                            <div
                              onClick={e => {
                                e.preventDefault();
                                onCheck(data.spaceId);
                              }}
                            >
                              审查空间
                            </div>
                          ),
                        },
                        {
                          key: 'ifc-submenu',
                          label: 'IFC操作',
                          children: [
                            {
                              key: 'ifc',
                              label: (
                                <div
                                  onClick={e => {
                                    e.preventDefault();
                                    TuzhiVisService.getIFCBySpaceId(data.spaceId)
                                      .then(res => {
                                        saveAs(res.data, `model-${data.spaceId}.ifc`);
                                        message.success('IFC文件下载成功');
                                      })
                                      .catch(err => {
                                        message.error('IFC文件下载失败，请注意只允许导出融合空间的IFC');
                                      });
                                  }}
                                >
                                  下载IFC
                                </div>
                              ),
                            },
                            {
                              key: 'reload-ifc',
                              label: (
                                <div
                                  onClick={e => {
                                    e.preventDefault();
                                    TuzhiVisService.refreshIFCBySpaceId(data.spaceId)
                                      .then(res => {
                                        message.success('刷新IFC成功');
                                      });
                                  }}
                                >
                                  刷新IFC
                                </div>
                              ),
                            },
                          ],
                        },
                        {
                          key: 'custom-export',
                          label: '定制数据导出',
                          children: [
                            {
                              key: 'bcjson',
                              label: (
                                <div
                                  onClick={e => {
                                    e.preventDefault();
                                    TuzhiVisService.getBCJSONBySpaceId(data.spaceId)
                                      .then(res => {
                                        saveAs(res.data, `model-${data.spaceId}.json`);
                                        message.success('BCJSON文件下载成功');
                                      });
                                  }}
                                >
                                  导出BCJSON
                                </div>
                              ),
                            },
                            {
                              key: 'tianhua',
                              label: (
                                <div
                                  onClick={e => {
                                    const key = `tianhua-${data.spaceId}`;
                                    message.loading({content: '数据导出中，请稍后……', key});
                                    TuzhiVisService.exportDataBySpaceId(data.spaceId, 'tianhua-space')
                                      .then(res => {
                                        saveAs(res.data, `${key}.json`);
                                        message.success({content: '导出完成，保存成功', key, duration: 2});
                                      })
                                      .catch(err => {
                                        message.error({content: '导出失败，请检查网络', key, duration: 2});
                                      });
                                  }}
                                >
                                  天华空间信息导出
                                </div>
                              ),
                            },
                          ],
                        },
                      ] : [],
                  ],
                  style: {minWidth: 100, textAlign: 'center'},
                }} trigger={['contextMenu']}>
                  <div className={styles.treeItem}>
                    <div className={styles.name}>{data.spaceId && data?.children?.length === 0 ?
                      <Tag>{data.spaceId.toString()}</Tag> : ''}{data.title}</div>
                  </div>
                </Dropdown>
              );
          }}
        />
      </div>
    </div>
  );
}

export default SpaceTree;