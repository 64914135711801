import * as FlexLayout from 'flexlayout-react';

export const checkLayout: FlexLayout.IJsonModel = {
  global: {
    splitterSize: 4,
    splitterExtra: 4,
    tabSetEnableTabStrip: false,
    rootOrientationVertical: true,
  },
  borders: [],
  layout: {
    type: 'row',
    children: [
      {
        type: 'row',
        children: [
          {
            type: 'tabset',
            weight: 1,
            children: [
              {
                type: 'tab',
                id: 'check-result-list',
                component: 'result-list',
              },
            ],
          },
          {
            type: 'tabset',
            weight: 3,
            children: [
              {
                type: 'tab',
                id: 'check-report',
                component: 'report',
              },
            ],
          },
        ],
      },
    ],
  },
}
