import React, { useEffect, useRef, useState } from 'react';
import styles from './IFCViewer.module.less';
import * as THREE from 'three';
import * as OBC from 'openbim-components';
import * as WEBIFC from 'web-ifc';
import Loading from '../../../../components/Loading';
import TuzhiVisService from '../../TuzhiVis.service';
import { message } from 'antd';
import { useResizeDetector } from 'react-resize-detector';
import { IfcPropertiesUtils } from 'openbim-components/src/ifc/IfcPropertiesUtils';

interface IFCViewerProps {
  spaceId: number;
}

function IFCViewer(props: IFCViewerProps) {
  const {spaceId} = props;
  const ifcRef = useRef<HTMLDivElement>(null);

  const [components, setComponents] = useState<OBC.Components | null>(null);
  const [selectedFragmentId, setSelectedFragmentId] = useState([]);


  const [loaded, setLoaded] = useState(false);

  const { width, height, ref: resizeRef } = useResizeDetector({
    // refreshMode: 'throttle',
    // refreshRate: 500
  });

  useEffect(() => {
    let fragments: OBC.FragmentManager;

    // 异步的IFCViewer加载
    const initIFCViewer = async () => {
      if (!ifcRef.current) return;
      const components = new OBC.Components();
      setComponents(components);

      // 初始化场景、渲染器、相机、射线投射
      const scene = new OBC.SimpleScene(components);
      components.scene = scene;
      const renderer = new OBC.PostproductionRenderer(components, ifcRef.current, {
        antialias: true,
        alpha: true,
        preserveDrawingBuffer: true,
      });
      components.renderer = renderer;
      const camera = new OBC.SimpleCamera(components);
      components.camera = camera;
      const raycaster = new OBC.SimpleRaycaster(components);
      components.raycaster = raycaster;
      await components.init();

      renderer.postproduction.enabled = true;

      const threeScene = components.scene.get();

      await camera.controls.setLookAt(12, 6, 8, 0, 0, -10);

      await scene.setup();

      const grid = new OBC.SimpleGrid(components, new THREE.Color(0x666666));
      const customEffects = renderer.postproduction.customEffects;
      customEffects.excludedMeshes.push(grid.get());

      // 转换IFC到Fragment
      fragments = new OBC.FragmentManager(components);
      const fragmentIfcLoader = new OBC.FragmentIfcLoader(components);

      // wasm配置
      fragmentIfcLoader.settings.wasm = {
        path: "https://unpkg.com/web-ifc@0.0.46/",
        absolute: true,
      }

      // 排除分类
      const excludedCats = [
        WEBIFC.IFCTENDONANCHOR,
        WEBIFC.IFCREINFORCINGBAR,
        // WEBIFC.IFCREINFORCINGELEMENT,
      ];

      for(const cat of excludedCats) {
        fragmentIfcLoader.settings.excludedCategories.add(cat);
      }
      fragmentIfcLoader.settings.webIfc.COORDINATE_TO_ORIGIN = true;
      fragmentIfcLoader.settings.webIfc.OPTIMIZE_PROFILES = true;


      // todo 简单注册点击事件
      // @ts-ignore
      const highlighter = new OBC.FragmentHighlighter(components, fragments);
      renderer.postproduction.customEffects.outlineEnabled = true;
      // @ts-ignore
      highlighter.outlinesEnabled = true;
      const highlightMaterial = new THREE.MeshBasicMaterial({
        color: '#BCF124',
        depthTest: false,
        opacity: 0.8,
        transparent: true
      });
      await highlighter.add('default', [highlightMaterial]);
      highlighter.outlineMaterial.color.set(0xf0ff7a);

      // 请求加载模型文件
      message.info('加载模型文件中');
      const res = await TuzhiVisService.getIFCBySpaceId(spaceId);
      const ifcFile = new File([res.data], 'tuzhi.ifc');
      const data = await ifcFile.arrayBuffer();
      const buffer = new Uint8Array(data);
      const model = await fragmentIfcLoader.load(buffer, 'tuzhi-ifc');
      threeScene.add(model);
      setLoaded(true);
      // 模型数据
      // console.log(model);
      // 属性数据
      const properties = model.properties;
      const propsProcessor = new OBC.IfcPropertiesProcessor(components);
      const propsManager = new OBC.IfcPropertiesManager(components);
      propsProcessor.propertiesManager = propsManager;

      // 基点球体
      const earthGeometry = new THREE.SphereGeometry( 1, 16, 16 );
      const earthMaterial = new THREE.MeshBasicMaterial( { color: 0xff0000 } );
      const earthMesh = new THREE.Mesh( earthGeometry, earthMaterial );
      earthMesh.scale.set(-1, 1, 1);
      threeScene.add(earthMesh);

      await highlighter.update();

      // 高亮工具的点击事件
      highlighter.events.default.onHighlight.add((selection) => {
        console.log(selection);
        // const fragmentID = Object.keys(selection)[0];
        //   // @ts-ignore
        // const expressID = [...selection[fragmentID]][0];
        //   let model
        //   for (const group of fragments.groups) {
        //     const fragmentFound = Object.values(group.keyFragments).find(id => id === fragmentID)
        //     if (fragmentFound) model = group;
        //   }
        //   if (model) {
        //     // const p = propsProcessor.getProperties(model, expressID);
        //     const p = propsProcessor.getProperties(model, Object.keys(selection)[0]);
        //     propsProcessor.renderProperties(model, expressID);
        //     if (model.properties) {
        //       console.log(model.properties[expressID]);
        //       const entityName = IfcPropertiesUtils.getEntityName(model.properties, expressID);
        //       console.log(entityName);
        //       const pset = IfcPropertiesUtils.getPsetProps(model.properties, expressID);
        //       console.log(pset);
        //     }
        //   } else {
        //     console.log('没model');
        //   }
        }
      );

      let lastSelection;
      async function highlightOnClick(event: any) {
        const result = await highlighter.highlight('default', true);
        console.log(result);
        if (result) {
          lastSelection = {};
          for (const fragment of result.fragments) {
            console.log(fragment.id);
            const fragmentID = fragment.id;
            // @ts-ignore
            lastSelection[fragmentID] = [result.id];
          }
        }
      }
      ifcRef.current.addEventListener('click', (event) => highlightOnClick(event));


      // 拿到所有ifc属性
      if (!fragments.groups.length) return;
      const group = fragments.groups[0];
      // 所有的ifc数据
      // 筛选其中ifcspace对象，并拿到名称，在对应位置增加CSS2D标签
      // @ts-ignore
      const ifcSpace = Object.values(properties).filter(property => property.type === 3856911033);
      console.log(ifcSpace);
      // if (ifcSpace.length) {
      //   const ifcSpaceName = ifcSpace[0].name;
      //   const ifcSpacePosition = ifcSpace[0].position;
      //   const labelDiv = document.createElement('div');
      //   labelDiv.className = styles.ifcSpaceLabel;
      //   labelDiv.textContent = ifcSpaceName;
      //   const label = new CSS2DObject(labelDiv);
      //   // label.position.set(ifcSpacePosition.x, ifcSpacePosition.y, ifcSpacePosition.z);
      //   threeScene.add(label);
      // }
    }

    if (ifcRef.current) {
      // 初始化viewer
      initIFCViewer().finally(() => {
        return () => {
          if (fragments)
            fragments.dispose();
        };
      });
    }
  }, [])

  useEffect(() => {
    if (components && width && height) {
      const threeRenderer = components.renderer.get();
      const threeCamera = components.camera.get();
      if (threeCamera instanceof THREE.PerspectiveCamera) {
        threeRenderer.setSize(width, height);
        threeCamera.aspect = width / height;
        threeCamera.updateProjectionMatrix();
      }
    }
  }, [width, height]);

  return (
    <div className={styles.root} ref={resizeRef}>
      {!loaded && <Loading />}
      <div ref={ifcRef} className={styles.ifcViewer} style={{
        width: width,
        height: height,
      }} />
    </div>
  );
}

export default IFCViewer;