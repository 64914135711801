import React, { useEffect, useRef, useState } from 'react';
import styles from './SemanticLayer.module.less';
import * as PIXI from 'pixi.js';
import { Assets, Spritesheet } from 'pixi.js';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  fetchGeomsBySpaceId,
  selectElements,
  selectGeomsBySpaceId,
  selectHoveredElementIds,
  selectSelectedElementsBySpaceId,
  selectSemanticLayerStatesBySpaceId,
  selectSpaces,
  setHoveredElementIds,
  setSelectedElementIdsBySpaceId,
} from '../../tuzhiVisSlice';
import { getRenderProperties } from './renderConfig';
import { bbox, booleanPointInPolygon, MultiPolygon, point } from '@turf/turf';
import RBush from 'rbush';
import { Element, Geom } from '../../../../interfaces';
import '../custom-shapes';
import { screenToReal } from '../utils/transformUtils';
import { Polygon } from 'geojson';
import { Item, Menu, useContextMenu } from 'react-contexify';

import 'react-contexify/dist/ReactContexify.css';
import { findPointElements } from '../utils/geometryUtils';
import { isEqual } from 'lodash';
import { Button, message } from 'antd';
import { selectMode, setMode, setModifyingGeomId } from '../modifier-layer/modifierSlice';
import ModifierService from '../modifier-layer/Modifier.service';

interface SemanticLayerProps {
  spaceId: number; // 空间ID，用于获取需要的构件与包围盒数据
  width: number;
  height: number;
  matrix: number[]; // 缩放平移的矩阵
  // scale: number; // 缩放比例
  className: string | undefined;
  realPoint: number[];
  isMouseDown: boolean;
  onRendered?: () => void;
}

function SemanticLayer(props: SemanticLayerProps) {
  const {spaceId, width, height, matrix, className, realPoint, isMouseDown, onRendered} = props;

  const dispatch = useAppDispatch();
  const geoms = useAppSelector(state => selectGeomsBySpaceId(state, spaceId)); // 包围盒数据
  const elements = useAppSelector(selectElements); // 构件数据
  const selectedElementIds = useAppSelector(state => selectSelectedElementsBySpaceId(state, spaceId));
  const hoveredElementIds = useAppSelector(selectHoveredElementIds);
  const semanticLayerStates = useAppSelector(state => selectSemanticLayerStatesBySpaceId(state, spaceId));
  const modifyMode = useAppSelector(selectMode);

  // RTree相关，用于构件穿透与定位
  const [rtree, setRtree] = useState<RBush<{geom: Geom}> | null>(null);

  // PIXI可视化相关
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [pixiApp, setPixiApp] = useState<PIXI.Application | null>(null);
  const [clickStart, setClickStart] = useState([0, 0]); // 鼠标点击位置
  const [spriteSheets, setSpriteSheets] = useState<{[key: string]: Spritesheet}>({});

  // 右键菜单相关
  const [menuElements, setMenuElements] = useState<Element[]>([]);
  const { show } = useContextMenu({id: 'element-menu'});

  // 构造rtree
  useEffect(() => {
    // 构造rtree数据，用于穿透定位
    const tree = new RBush<{geom: Geom}>();
    tree.load(geoms
      .filter(g => g.geom !== null)
      .map((geom) => {
        const aabb = bbox(geom.geom);
        return {
          minX: aabb[0],
          minY: aabb[1],
          maxX: aabb[2],
          maxY: aabb[3],
          geom: geom,
        };
      }));
    setRtree(tree);
  }, [geoms]);

  // 初始化PIXI画布
  useEffect(() => {
    const canvasElement = canvasRef.current;
    if (canvasElement) {
      console.log('重新初始化语义图层');
      // 初始化PIXI
      const app = new PIXI.Application({
        view: canvasElement,
        antialias: true,
        backgroundAlpha: 0,
        width: width,
        height: height,
        // resolution: devicePixelRatio,  // todo 与设备比例尺相关
        // resizeTo: canvasWrapperElement
      });
      setPixiApp(app);

      // 初始化参数
      PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.NEAREST;

      // 初始化transform
      app.stage.x = matrix[4];
      app.stage.y = matrix[5];
      app.stage.scale.x = matrix[0];
      app.stage.scale.y = matrix[3];

      return () => {
        console.log('注销语义图层');
        pixiApp?.stage.destroy();
        pixiApp?.destroy();
        setPixiApp(null);
      };
    }
  }, []);

  // 更新PIXI画布大小
  useEffect(() => {
    if (pixiApp && width && height && pixiApp.view.style) {
      const newWidth = Math.ceil(width);
      const newHeight = Math.ceil(height);
      pixiApp.view.style.width = `${newWidth}px`;
      pixiApp.view.style.height = `${newHeight}px`;
      pixiApp.renderer.resize(newWidth, newHeight);
    }
  }, [width, height]);

  // 更新PIXI平移缩放状态
  useEffect(() => {
    if (pixiApp) {
      pixiApp.stage.x = matrix[4];
      // 注意，height是网页canvas尺寸，需要使用pdf尺寸作为真实尺寸
      pixiApp.stage.y = matrix[5]; // todo
      pixiApp.stage.scale.x = matrix[0];
      pixiApp.stage.scale.y = matrix[3]; // todo 实际数据改正确才对
    }
  }, [matrix]);

  // 构件变化时，筛选并加载纹理
  useEffect(() => {
    const sheetNames = Array.from(new Set(
      geoms
        .filter(g => g.geom !== null)
        .flatMap((geom) => {
          const element = elements[geom.elementId];
          if (!element)
            return [];
          const renderProperties = getRenderProperties(element);
          return renderProperties.spriteSheet ? [renderProperties.spriteSheet] : [];
        }),
    ));
    Promise
      .all(sheetNames.map(sheet => {
        return Assets.load(`/static/spritesheets/${sheet}.json`);
      }))
      .then(sheets => {
        setSpriteSheets(Object.fromEntries(sheets.map((s, i) => [sheetNames[i], s])));
      });
  }, [geoms, elements]);

  // 生成缓存的全构件语义图层
  useEffect(() => {
    try {
      console.log('重新初始化语义图层内容构件');
      if (pixiApp) {
        // 初始化Container
        console.log('开始初始化底图图层');
        const container = new PIXI.Container();
        // 将全部几何绘制在同一个graphics中，提高性能
        const baseGraphics = new PIXI.Graphics();
        // 构造渲染几何列表
        geoms
          .filter(g => g.geom !== null)
          .forEach((geom) => {
            const element = elements[geom.elementId];
            if (!element) {
              console.warn(geom.elementId);
            }
            const renderProperties = getRenderProperties(element);

            // 更新使用图层可见性数据控制渲染
            if (!semanticLayerStates[`${element.categoryName}###${element.familyName}`])
              return null;

            baseGraphics.lineStyle(1, 0xf0f, 1, 0, true);
            let fillColor = renderProperties.fill;
            if (typeof fillColor === 'function') {
              fillColor = fillColor(element);
            }
            baseGraphics.alpha = 0.8;
            baseGraphics.beginFill(fillColor, renderProperties.alpha);
            baseGraphics.drawGeoJSON(geom.geom);
            baseGraphics.endFill();

            // 增加构件纹理渲染
            if (renderProperties.spriteSheet && renderProperties.sprite) {
              const texture = spriteSheets[renderProperties.spriteSheet].textures[renderProperties.sprite];
              if (!texture) {
                console.warn('不存在的纹理数据:', renderProperties.spriteSheet, renderProperties.sprite);
                return;
              }
              const sprite = new PIXI.Sprite(texture);
              // 注意Y轴翻转
              const aabb = bbox(geom.geom);
              sprite.alpha = 0.8;
              let angle = renderProperties.rotation;
              // 如果是函数计算则调用
              if (typeof angle === 'function') {
                angle = angle(element);
              }
              // 如果是未定义数值，默认是90度，即向正上方，但若长宽比相反，则暂时当作180度
              if (angle === undefined) {
                const textureRatio = texture.width / texture.height;
                const aabbRatio = (aabb[2] - aabb[0]) / (aabb[3] - aabb[1]);
                if ((aabbRatio > 1.2 || aabbRatio < 0.8) && textureRatio * aabbRatio - 1 < 0.1)
                  angle = 180;
                else
                  angle = 90;
              }
              sprite.anchor.set(0.5, 0.5);
              sprite.x = (aabb[0] + aabb[2]) / 2;
              sprite.y = (aabb[1] + aabb[3]) / 2;
              sprite.angle = angle - 90;
              if (angle === 0 || angle === 180) {
                sprite.scale.x = (aabb[2] - aabb[0]) / texture.height;
                sprite.scale.y = -(aabb[3] - aabb[1]) / texture.width;
              } else {
                sprite.scale.x = (aabb[2] - aabb[0]) / texture.width;
                sprite.scale.y = -(aabb[3] - aabb[1]) / texture.height;
              }
              container.addChild(sprite);
            }
          });

        container.addChild(baseGraphics);
        pixiApp.stage.addChild(container);
        pixiApp.renderer.render(pixiApp.stage);

        // 至此初始化图层完毕，调用通知父组件渲染完毕
        if (onRendered)
          onRendered();

        return () => {
          console.log('注销底图');
          container.destroy({
            children: true,
          });
        };
      }
    } catch (e) {
      console.error(e);
    }
  }, [pixiApp, geoms, elements, spriteSheets, semanticLayerStates]);

  // 选择构件的动画
  useEffect(() => {
    if (pixiApp) {
      const animateG = new PIXI.Graphics();
      pixiApp.stage.addChild(animateG);

      const ticker = new PIXI.Ticker();
      ticker.add(() => {
        animateG.clear();
        animateG.lineStyle(0);
        selectedElementIds
          .filter(id => {
            const e = elements[id];
            return semanticLayerStates[`${e.categoryName}###${e.familyName}`];
          })
          .forEach(elementId => {
            const e = elements[elementId];
            const geom = geoms.find(g => g.geomId === e.geoms?.[0])?.geom;
            if (geom) {
              animateG.lineStyle(0);
              animateG.beginFill(0x0000ff, 0.3);
              animateG.drawGeoJSON(geom);
              animateG.endFill();
              animateG.drawDashedGeoJSON(geom, pixiApp.stage.scale.x, 5, 3, new Date().getTime() / 30 % 8);
            }
          });
      }, PIXI.UPDATE_PRIORITY.LOW);
      ticker.start();
      return () => {
        if (pixiApp.stage)
          pixiApp.stage.removeChild(animateG);
        animateG.destroy();
        ticker.destroy();
      };
    }
  }, [selectedElementIds]);

  // 响应鼠标悬停事件
  useEffect(() => {
    // 如果当前是修改状态，则不高亮任何构件
    if (modifyMode !== 'none') {
      dispatch(setSelectedElementIdsBySpaceId({spaceId, elementIds: []}));
      dispatch(setHoveredElementIds([]));
      return;
    }
    // 在鼠标按下时不更新，避免闪烁
    if (rtree && !isMouseDown) {
      const pointElements = findPointElements(rtree, realPoint);
      // 根据图层可见性过滤
      const filteredElementIds = pointElements?.flatMap(eId => {
        const element = elements[eId];
        // 更新使用图层可见性数据控制渲染
        if (!semanticLayerStates[`${element.categoryName}###${element.familyName}`])
          return [];
        return [eId];
      })?.slice(0, 1); // 目前只保留hover一个构件
      if (!isEqual(hoveredElementIds, filteredElementIds))
        dispatch(setHoveredElementIds(filteredElementIds));
    }
  }, [realPoint, isMouseDown, modifyMode]);

  // 悬停构件的动画
  useEffect(() => {
    if (pixiApp) {
      const animateG = new PIXI.Graphics();
      pixiApp.stage.addChild(animateG);

      const ticker = new PIXI.Ticker();
      ticker.add(() => {
        animateG.clear();
        animateG.lineStyle(0);
        hoveredElementIds
          .filter(id => {
            const e = elements[id];
            return semanticLayerStates[`${e.categoryName}###${e.familyName}`];
          })
          .forEach(elementId => {
            const e = elements[elementId];
            const geom = geoms.find(g => g.geomId === e.geoms?.[0])?.geom;
            if (geom) {
              animateG.lineStyle(0);
              animateG.beginFill(0x0000ff, 0.2);
              animateG.drawGeoJSON(geom);
              animateG.endFill();
              animateG.drawDashedGeoJSON(geom, pixiApp.stage.scale.x, 3, 2, new Date().getTime() / 25 % 8);
            }
          });
      }, PIXI.UPDATE_PRIORITY.LOW);
      ticker.start();
      return () => {
        if (pixiApp.stage) {
          pixiApp.stage.removeChild(animateG);
        }
        animateG.destroy();
        ticker.destroy(); // todo
      };
    }
  }, [pixiApp, hoveredElementIds]);

  const onPolygonClick = () => {
    // 通过rtree判断当前选中的构件
    if (rtree) {
      const pointElements = findPointElements(rtree, realPoint);
      // 根据图层可见性过滤
      const filteredElementIds = pointElements?.flatMap(eId => {
        const element = elements[eId];
        // 更新使用图层可见性数据控制渲染
        if (!semanticLayerStates[`${element.categoryName}###${element.familyName}`])
          return [];
        return [eId];
      })?.slice(0, 1); // 目前只保留hover一个构件
      dispatch(setSelectedElementIdsBySpaceId({spaceId, elementIds: filteredElementIds}));
    }
  };
  const toggleSelection = () => {
    if (rtree) {
      const pointElements = findPointElements(rtree, realPoint);
      // 根据图层可见性过滤
      const filteredElementIds = pointElements?.flatMap(eId => {
        const element = elements[eId];
        // 更新使用图层可见性数据控制渲染
        if (!semanticLayerStates[`${element.categoryName}###${element.familyName}`])
          return [];
        return [eId];
      })?.slice(0, 1); // 目前只保留点击一个构件
      
      // 切换选中状态
      if (filteredElementIds) {
        const index = selectedElementIds.indexOf(filteredElementIds[0]);
        if (index === -1) {
          // 如果当前选中列表中没有该构件，添加进选中列表
          dispatch(setSelectedElementIdsBySpaceId({ spaceId, elementIds: [...selectedElementIds, filteredElementIds[0]] }));
        } else {
          // 如果当前选中列表中已经有该构件，从选中列表中移除
          const newSelectedElementIds = [...selectedElementIds];
          newSelectedElementIds.splice(index, 1);
          dispatch(setSelectedElementIdsBySpaceId({ spaceId, elementIds: newSelectedElementIds }));
        }
      }
    }
  };

  const delta = 4;

  const onMouseDown = (e : React.MouseEvent<HTMLCanvasElement>) => {
    setClickStart([e.pageX, e.pageY]);
  };

  const onMouseUp = (e: React.MouseEvent<HTMLCanvasElement>) => {
    const diffX = Math.abs(e.pageX - clickStart[0]);
    const diffY = Math.abs(e.pageY - clickStart[1]);
    if (diffX < delta && diffY < delta) {
      // 如果按下了 Ctrl 键，执行 toggleSelection 函数
      if (e.ctrlKey) {
        toggleSelection();
        return;
      } else {
      // 如果没有按下 Ctrl 键，则执行原来的点击事件逻辑
        onPolygonClick();
      }
    }
  }

  return (
    <div
      className={className}
      style={{
        width, height,
      }}
    >
      <canvas
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onContextMenu={(e) => {
          e.preventDefault();
          const bound = e.currentTarget.getBoundingClientRect();
          const realPoint = screenToReal([e.clientX - bound.x, e.clientY - bound.y], matrix);
          const selected = rtree
            ?.search({
              minX: realPoint[0], minY: realPoint[1], maxX: realPoint[0], maxY: realPoint[1],
            })
            .flatMap(node => {
              try {
                if (['Polygon', 'MultiPolygon'].includes(node.geom.geom.type)) {
                  if (booleanPointInPolygon(point(realPoint), node.geom.geom as Polygon | MultiPolygon)) {
                    const element = elements[node.geom.elementId.toString()];
                    console.log(element.elementId + element.elementName);
                    return [element];
                  }
                }
              } catch (e) {
                console.log(e);
                console.log(node.geom);
              }
              return [];
            });
          if (selected) {
            setMenuElements(selected);
            show({
              event: e,
            });
          }
        }}
        ref={canvasRef}
        className={styles.tuzhiLayerCanvas}
      />
      <Menu id="element-menu">
        {
          menuElements.map(element => (
            <Item key={element.elementId} onClick={() => {
              dispatch(setSelectedElementIdsBySpaceId({spaceId, elementIds: [element.elementId]}));
            }}>
              {element.elementId}: {element.elementName}{' '}
              <Button.Group>
                <Button
                  size="small"
                  onClick={(e) => {
                    console.log(element.geoms);
                    // todo 目前因为没有出现构件在多个空间的情况，暂时只取第一个
                    dispatch(setMode('modify'));
                    dispatch(setModifyingGeomId(element.geoms?.[0] ?? null));
                  }}
                >
                  修改
                </Button>
                <Button
                  size="small"
                  onClick={(e) => {
                    if (element.geoms?.[0]) {
                      ModifierService.modifyGeometry(element.geoms?.[0], null)
                        .then(() => {
                          message.success('删除成功');
                          dispatch(fetchGeomsBySpaceId(spaceId));
                          dispatch(setSelectedElementIdsBySpaceId({spaceId, elementIds: []}));
                        })
                        .catch(() => {
                          message.error('删除失败');
                        });
                    }
                  }}
                >
                  删除
                </Button>
              </Button.Group>
            </Item>
          ))
        }
      </Menu>
    </div>
  );
}

export default SemanticLayer;