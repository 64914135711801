import React, { useEffect, useState, createContext, useContext } from "react";

import { Button, Space } from "antd";
import styles from "./ConsistencyViewer.module.less";
import { Helmet } from "react-helmet";
import * as FlexLayout from "flexlayout-react";
import { Navigate, useParams } from "react-router-dom";
import ConsistencyAsset from "./consistency-asset/ConsistencyAsset";
import DiffFunction from "./diff-function/DiffFunction";
import UnionViewer2D from "../tuzhi-vis/union-viewer/UnionViewer2D";
import StoreyList from "./storey-list/StoreyList";
import ConsistencyIFCViewer from "./consisitency-ifc-viewer/ConsistencyIFCViewer";
import { ActivePdfItemViewer } from "./pdf-item-viewer/PdfItemViewer";
import BimItemViewer from "./bim-item-viewer/BimItemViewer";
import ResultList from "./result-list/ResultList";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import TuzhiVisService from "../tuzhi-vis/TuzhiVis.service";
import ConsistencyViewerService from "./ConsistencyViewer.service";
import {
  setProjectId,
  setElevation,
  selectTuZhiProjectId,
  setTuZhiProjectId,
  clearConsistencyCache,
  setTuZhiSpaceId,
} from "./consistencyViewerSlice";
import {
  activateLog,
  appendLog,
  clearCache,
  deactivateLog,
  fetchPluginsByProjectId,
  fetchSNLs,
  fetchSpaceRelations,
  selectLog,
  setActiveSpaceId,
  setAssets,
  setChecks,
  setProject,
  setSpaces,
  setTasks,
} from "../tuzhi-vis/tuzhiVisSlice";

const defaultLayout: FlexLayout.IJsonModel = {
  global: {
    splitterSize: 4,
    splitterExtra: 4,
  },
  borders: [],
  layout: {
    type: "row",
    id: "root",
    children: [
      {
        type: "row",
        id: "left-pane",
        weight: 1,
        children: [
          {
            type: "tabset",
            id: "asset-pane",
            weight: 8,
            children: [
              {
                type: "tab",
                id: "consistency-asset",
                name: "资源管理",
                component: "consistency-asset",
              },
            ],
            active: true,
          },
          {
            type: "tabset",
            id: "diff-pane",
            weight: 4,
            children: [
              {
                type: "tab",
                id: "progress",
                name: "比对操作",
                component: "diff-function",
              },
            ],
          },
          {
            type: "tabset",
            id: "storey-pane",
            weight: 8,
            children: [
              {
                type: "tab",
                id: "space",
                name: "层级管理",
                component: "storey-tree",
              },
            ],
          },
        ],
      },
      {
        type: "row",
        id: "right-pane",
        weight: 5,
        children: [
          {
            type: "row",
            id: "viewer-pane",
            weight: 4,
            children: [
              {
                type: "tabset",
                id: "pdf-viewer-tab",
                weight: 2,
                enableClose: false,
                children: [
                  {
                    type: "tab",
                    id: "pdf-viewer",
                    name: "图纸展示",
                    component: "pdf-viewer",
                  },
                ],
              },
              {
                type: "tabset",
                id: "bim-viewer-tab",
                weight: 2,
                enableClose: false,
                children: [
                  {
                    type: "tab",
                    id: "bim-viewer",
                    name: "模型展示",
                    component: "bim-viewer",
                  },
                ],
              },
            ],
          },
          {
            type: "row",
            id: "bottom-pane",
            weight: 1,
            children: [
              {
                type: "tabset",
                id: "pdf-item-viewer-tab",
                weight: 2,
                enableClose: false,
                children: [
                  {
                    type: "tab",
                    id: "pdf-item-viewer",
                    name: "图纸构件属性",
                    component: "pdf-item-viewer",
                  },
                ],
              },
              {
                type: "tabset",
                id: "bim-item-viewer-tab",
                weight: 2,
                children: [
                  {
                    type: "tab",
                    id: "bim-item-viewer",
                    name: "模型构件属性",
                    component: "bim-item-viewer",
                  },
                ],
              },
              {
                type: "tabset",
                id: "result-list-tab",
                weight: 7,
                children: [
                  {
                    type: "tab",
                    id: "result-list",
                    name: "检查结果",
                    component: "result-list",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

interface ConsistencyViewerProps {
  username: string;
  projectId: number;
}

export const LayoutContext = createContext<{
  model: FlexLayout.Model;
}>({
  model: FlexLayout.Model.fromJson(defaultLayout),
});

function ConsistencyViewer(props: ConsistencyViewerProps) {
  const { projectName, projectId: projectIdFromParams } = useParams();
  // projectId转为number
  const projectId = parseInt(projectIdFromParams!, 10);
  const dispatch = useAppDispatch();
  const [layoutModel, setLayoutModel] = useState<FlexLayout.Model>(
    FlexLayout.Model.fromJson(defaultLayout)
  );
  const tuzhiProjectId = useAppSelector(selectTuZhiProjectId);
  useEffect(() => {
    dispatch(clearCache());
    dispatch(clearConsistencyCache());
  }, []);
  useEffect(() => {
    ConsistencyViewerService.getConsistenyTuzhiId(projectId).then(
      (res: any) => {
        if (res.data["exist"]) {
          dispatch(setTuZhiProjectId(parseInt(res.data["id"])));
        }
      }
    );
  }, []);
  useEffect(() => {
    if (tuzhiProjectId !== null) {
      TuzhiVisService.getSpacesByProjectId(tuzhiProjectId).then((res) => {
        const spaces = res.data;
        const filteredSpaces = spaces.filter(
          (space: any) =>
            space.granularity === "MERGED" && space.elevation !== null
        );
        // dispatch(setElevation(elevations[0]));
        dispatch(setSpaces(res.data));
        dispatch(setTuZhiSpaceId(filteredSpaces[0].spaceId));
        // const elevations = filteredSpaces.map((space: any) => space.elevation);
        // if (elevations.length > 0) {
        //   dispatch(setElevation(elevations[0]));
        //   dispatch(setActiveSpaceId(filteredSpaces[0].spaceId));
        //   dispatch(setSpaces(res.data));
        //   dispatch(setTuZhiSpaceId(filteredSpaces[0].spaceId));
        //   // todo:修改layoutModel中id为pdf-viewer的name
        //   setLayoutModel((prevModel) => {
        //     prevModel.doAction(
        //       FlexLayout.Actions.updateNodeAttributes("pdf-viewer", {
        //         name: `${elevations[0]}层图纸空间`,
        //         config: { spaceId: filteredSpaces[0].spaceId },
        //       })
        //     );
        //     return prevModel;
        //   });
        // }
      });
    }
  }, [tuzhiProjectId]);
  return (
    <LayoutContext.Provider
      value={{
        model: layoutModel,
      }}
    >
      <div className={styles.root}>
        <div className={styles.projectTitle}>
          项目名：{projectName} &nbsp;项目id: {projectId}
        </div>
        <div className={styles.dockWrapper}>
          <FlexLayout.Layout
            model={layoutModel}
            font={{ size: "12px" }}
            // realtimeResize
            factory={(node) => {
              const config = node.getConfig();
              const spaceId =
                config && "spaceId" in config ? config.spaceId : null;
              switch (node.getComponent()) {
                case "consistency-asset":
                  return <ConsistencyAsset projectId={projectId} />;
                case "diff-function":
                  return <DiffFunction projectId={projectId} />;
                case "storey-tree":
                  return <StoreyList />;
                case "pdf-viewer":
                  if (spaceId) {
                    return <UnionViewer2D spaceId={spaceId} />;
                  } else {
                    return (
                      <div className={styles.unboundBox}>暂未绑定图智项目或未选择楼层</div>
                    );
                  }
                case "bim-viewer":
                  return <ConsistencyIFCViewer projectId={projectId} />;
                case "result-list":
                  return <ResultList projectId={projectId} />;
                case "pdf-item-viewer":
                  return <ActivePdfItemViewer temp="233" />;
                case "bim-item-viewer":
                  return <BimItemViewer />;
                // case 'progress':
                //   return <ProgressViewer projectId={Number(projectId)} />;
                // case 'check':
                //   return <ModelChecker />;
                // case 'element-properties':
                //   return <ActiveElementPropertyViewer temp="233" />;
                // case 'space-properties':
                //   return <SpacePropertyViewer temp="233" />;
                // case 'global-properties':
                //   return <SpacePropertyEditorList projectId={Number(projectId)} />;
                // case 'layer':
                //   return <LayerController />;
                // case 'plugin-dag':
                //   return <PluginDAG projectId={Number(projectId)} />;
                // case 'project-graph':
                //   return <ProjectGraph projectId={Number(projectId)} />;
                // case 'log':
                //   return <LogViewer projectId={Number(projectId)} />;
                // case 'project-summary':
                //   return <SummaryViewer projectId={Number(projectId)} />;
                default:
                  return <div></div>;
              }
            }}
            onContextMenu={(node: any, event: any) => {
              event.preventDefault();
              event.stopPropagation();
              if (node.getId() === "space") {
                alert(233);
              }
            }}
            onAction={(action: any) => {
              console.log(action);
              return action;
              // console.log('layout action', action);
              // if (action.type === 'FlexLayout_SelectTab') {
              //   // 精确的正则匹配，避免匹配到空间属性面板
              //   const match = action.data.tabNode.match(/space-(\d+)/);
              //   if (match)
              //     dispatch(setActiveSpaceId(match[1]));
              // }
              // // 比较tricky的实现，在action已经执行完毕后再判断当前tab的可见状态进行切换
              // setTimeout(() => {
              //   if (layoutModel.getNodeById('log')?.isVisible()) {
              //     dispatch(activateLog());
              //   } else {
              //     dispatch(deactivateLog());
              //   }
              // }, 0);
              // return action;
            }}
          />
        </div>
      </div>
    </LayoutContext.Provider>
  );
}

export default ConsistencyViewer;
