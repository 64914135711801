import React from "react";
import {
  Row,
  Col,
  Button,
  List,
  Select,
  InputNumber,
  Modal,
  Popover,
} from "antd";
import { useState, useEffect, useRef } from "react";
import styles from "./ResultList.module.less";
import ConsistencyViewerService from "../ConsistencyViewer.service";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { fixGeometry } from "../../tuzhi-vis/union-viewer/utils/geometryUtils";
import * as PIXI from "pixi.js";
import {
  setSelectedElementIdsBySpaceId,
  setActiveSpaceId,
  selectActiveSpaceId,
} from "../../tuzhi-vis/tuzhiVisSlice";
import {
  setProjectId,
  setElevation,
  selectElevation,
  setBimProperties,
  setSelectedRoomBimId,
  setSelectedMemberBimId,
} from "../consistencyViewerSlice";
import { set } from "lodash";
import wellknown from "wellknown";
import { bbox } from "@turf/turf";
import { prop } from "cheerio/lib/api/attributes";

interface ResultListProps {
  projectId: number;
}

interface ResultJson {
  roomResults: Array<any>;
  memberResults: Array<any>;
}

function ResultList(props: ResultListProps) {
  const { projectId } = props;
  const dispatch = useAppDispatch();
  const [data, setData] = useState<ResultJson>({
    roomResults: [],
    memberResults: [],
  });
  const elevation = useAppSelector(selectElevation);
  const [resultType, setResultType] = useState("all");
  const [pdfId, setPdfId] = useState<number>(0);
  const [filterPdfId, setFilterPdfId] = useState<number>(0);
  const [bimId, setBimId] = useState<number>(0);
  const [filterBimId, setFilterBimId] = useState<number>(0);
  const [type, setType] = useState("all");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pixiApp, setPixiApp] = useState<PIXI.Application | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const [container, setContainer] = useState<PIXI.Container | null>(null);
  const [baseGraphics, setBaseGraphics] = useState<PIXI.Graphics | null>(null);
  const [objectType, setObjectType] = useState<string>("roomResults");
  const [memberType, setMemberType] = useState<string>("all");

  const fetchData = () => {
    // 这里可以编写获取数据的逻辑
    ConsistencyViewerService.getConsistencyResult(projectId).then(
      (res: any) => {
        setData(res.data);
      }
    );
  };
  useEffect(() => {
    ConsistencyViewerService.getConsistencyResult(projectId).then(
      (res: any) => {
        setData(res.data);
      }
    );
    const intervalId = setInterval(() => {
      fetchData(); // 每隔4秒请求一次数据
    }, 4000);

    return () => {
      clearInterval(intervalId); // 在组件卸载时清除定时器
    };
  }, []);

  useEffect(() => {
    if (canvasRef.current && modalRef.current) {
      const app = new PIXI.Application({
        view: canvasRef.current,
        backgroundColor: "#f9f9f9",
        backgroundAlpha: 1,
        resizeTo: modalRef.current,
      });
      const container = new PIXI.Container();
      // 将全部几何绘制在同一个graphics中，提高性能
      const baseGraphics = new PIXI.Graphics();
      container.addChild(baseGraphics);
      setContainer(container);
      app.stage.addChild(container);
      app.renderer.render(app.stage);
      setBaseGraphics(baseGraphics);
      setPixiApp(app);
    }
  }, [canvasRef, modalRef]);

  const getResultByConsistency = (consistency: string) => {
    if (consistency == "Consistent") {
      return "空间一致";
    } else if (consistency == "HasCandidateInconsistent") {
      return "空间不匹配";
    } else {
      return "空间不匹配";
    }
  };
  const getResultColorByConsistency = (consistency: string) => {
    if (consistency == "Consistent") {
      return "green";
    } else {
      return "red";
    }
  };
  const getNameConsistency = (nameConsistency: string) => {
    if (nameConsistency == "Consistent") {
      return "功能名一致";
    } else if (nameConsistency == "SameShort") {
      return "房名差异过大";
    } else if (nameConsistency == "PdfUnknown") {
      return "图上房名未知";
    } else if (nameConsistency == "BimUnknown") {
      return "模上房名未知";
    } else {
      return "功能名不一致";
    }
  };
  const showResultType = (itemType: string, resultType: string) => {
    if (resultType == "all") {
      return true;
    } else {
      return itemType == resultType;
    }
  };
  const showMemberType = (itemType: string, memberType: string) => {
    if (memberType == "all") {
      return true;
    } else {
      return itemType == memberType;
    }
  };
  const showType = (consistency: string, type: string) => {
    if (type == "all") {
      return true;
    } else if (type == "consistent") {
      return consistency == "Consistent";
    } else {
      return consistency != "Consistent";
    }
  };
  const showFilterPdfId = (itemPdfId: string, filterPdfId: number) => {
    if (filterPdfId == 0) {
      return true;
    } else {
      return filterPdfId.toString() == itemPdfId;
    }
  };
  const showFilterBimId = (itemBimId: string, filterBimId: number) => {
    if (filterBimId == 0) {
      return true;
    } else {
      return filterBimId.toString() == itemBimId;
    }
  };
  const showModal = (pdfWkt: string, bimWkt: string) => {
    if (baseGraphics && container && pixiApp) {
      baseGraphics.clear();
      let minX = Infinity;
      let minY = Infinity;
      let maxX = -Infinity;
      let maxY = -Infinity;
      if (pdfWkt) {
        const geojson = wellknown.parse(pdfWkt);
        const aabb = bbox(geojson);
        minX = Math.min(aabb[0], minX);
        minY = Math.min(aabb[1], minY);
        maxX = Math.max(aabb[2], maxX);
        maxY = Math.max(aabb[3], maxY);
        baseGraphics.beginFill(0xff0000); // 设置填充颜色
        baseGraphics.lineStyle(2, 0x000000); // 设置线条样式
        baseGraphics.alpha = 0.4;
        // @ts-ignore
        baseGraphics.drawGeoJSON(geojson);
        baseGraphics.closePath(); // 闭合路径
        baseGraphics.endFill(); // 结束填充
      }
      if (bimWkt) {
        const geojson = wellknown.parse(bimWkt);
        const aabb = bbox(geojson);
        minX = Math.min(aabb[0], minX);
        minY = Math.min(aabb[1], minY);
        maxX = Math.max(aabb[2], maxX);
        maxY = Math.max(aabb[3], maxY);
        baseGraphics.beginFill(0x0000ff); // 设置填充颜色
        baseGraphics.lineStyle(2, 0x000000); // 设置线条样式
        baseGraphics.alpha = 0.4;
        // @ts-ignore
        baseGraphics.drawGeoJSON(geojson);
        baseGraphics.closePath(); // 闭合路径
        baseGraphics.endFill(); // 结束填充
      }
      if (minX && minY) {
        const xWidth = maxX - minX;
        const yHeight = maxY - minY;
        const xScale = 600 / xWidth;
        const yScale = 600 / yHeight;
        const scale = Math.min(xScale, yScale);
        console.log("scale:", xScale, yScale, scale);
        pixiApp.stage.position.x = 0 - minX * scale;
        pixiApp.stage.position.y = 0 + maxY * scale;
        pixiApp.stage.scale.x = scale;
        pixiApp.stage.scale.y = -scale;
        pixiApp.renderer.render(pixiApp.stage);
      }
    }
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // 获取当前空间id
  const activeSpaceId = useAppSelector(selectActiveSpaceId);
  return (
    <div className={styles.root}>
      {/* <Popover
        content={<a onClick={hide}>Close</a>}
        title="Title"
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
      >
        <Button type="primary">Click me</Button>
      </Popover> */}
      <Row className={styles.filterBox}>
        <Col span={4}>
          图元:
          <Select
            defaultValue="roomResults"
            style={{ width: 70, marginLeft: "10px" }}
            onChange={(value) => {
              setObjectType(value);
            }}
            options={[
              {
                value: "roomResults",
                label: "房间",
              },
              {
                value: "memberResults",
                label: "构件",
              },
            ]}
          />
        </Col>
        <Col span={4}>
          {objectType == "roomResults" ? "比对内容" : "比对构件"}
          {objectType == "roomResults" ? (
            <Select
              defaultValue="all"
              style={{ width: 70, marginLeft: "10px" }}
              onChange={(value) => {
                setResultType(value);
              }}
              options={[
                {
                  value: "all",
                  label: "全部",
                },
                {
                  value: "roomSpace",
                  label: "空间比对",
                },
                {
                  value: "functionName",
                  label: "功能名比对",
                },
              ]}
            />
          ) : (
            <Select
              defaultValue="all"
              style={{ width: 70, marginLeft: "10px" }}
              onChange={(value) => {
                setMemberType(value);
              }}
              options={[
                {
                  value: "all",
                  label: "全部",
                },
                {
                  value: "memberDoor",
                  label: "门",
                },
                {
                  value: "memberWindow",
                  label: "窗",
                },
              ]}
            />
          )}
        </Col>
        <Col span={4}>
          比对类型:
          <Select
            defaultValue="all"
            style={{ width: 70, marginLeft: "10px" }}
            onChange={(value) => {
              setType(value);
            }}
            options={[
              {
                value: "all",
                label: "全部",
              },
              {
                value: "inconsistent",
                label: "仅不一致",
              },
              {
                value: "consistent",
                label: "仅一致",
              },
            ]}
          />
        </Col>
        <Col span={4}>
          图上ID:
          <InputNumber
            min={0}
            max={100000000}
            size="small"
            value={pdfId}
            style={{ marginLeft: "10px", width: "70px" }}
            onChange={(value) => setPdfId(value ?? 0)}
          />
        </Col>
        <Col span={4}>
          模上ID:
          <InputNumber
            min={0}
            max={100000000}
            size="small"
            value={bimId}
            style={{ marginLeft: "10px", width: "70px" }}
            onChange={(value) => setBimId(value ?? 0)}
          />
        </Col>
        <Col span={4}>
          <Button
            size="small"
            className="storey-check-button"
            onClick={() => {
              setFilterPdfId(pdfId);
              setFilterBimId(bimId);
            }}
          >
            ID查找
          </Button>
          <Button
            size="small"
            className="storey-check-button"
            onClick={() => {
              setFilterBimId(0);
              setFilterPdfId(0);
            }}
          >
            清除ID
          </Button>
        </Col>
      </Row>
      <div className={styles.listWrapper}>
        {/* @ts-ignore */}
        {data[objectType].filter(
          (item: any) => item.elevation === elevation?.toString()
        ).length === 0 ? (
          <div style={{ textAlign: "center" }}>{elevation}层无比对结果</div>
        ) : (
          <div className={styles.storeyList}>
            {/* @ts-ignore */}
            {objectType == "roomResults"
              ? data[objectType].map((item: any, index) =>
                  item.elevation === elevation?.toString() &&
                  showResultType(item.resultType, resultType) &&
                  showType(item.consistency, type) &&
                  showFilterPdfId(item.bimId, filterBimId) &&
                  showFilterPdfId(item.pdfId, filterPdfId) ? (
                    <div className={styles.storeyItem} key={index}>
                      <div
                        className={styles.itemStyle}
                        style={{
                          flex: 1,
                        }}
                      >
                        id:{item.resultId}
                      </div>
                      <div
                        className={styles.itemStyle}
                        style={{
                          color: getResultColorByConsistency(item.consistency),
                        }}
                      >
                        {item.resultType == "roomSpace"
                          ? getResultByConsistency(item.consistency)
                          : getNameConsistency(item.consistency)}
                      </div>
                      <div className={styles.itemStyle}>
                        {item.resultType == "roomSpace"
                          ? "空间比对"
                          : "功能名比对"}
                      </div>
                      <div
                        className={styles.itemStyle}
                        style={{
                          flex: 1,
                        }}
                      >
                        {item.elevation}层
                      </div>
                      <div className={styles.itemStyle}>
                        pdfId:{item.pdfId? item.pdfId : "无"}
                      </div>
                      <div
                        className={styles.itemStyle}
                        style={{
                          flex: 1.5,
                        }}
                      >
                        <Button
                          size="small"
                          className="storey-check-button"
                          onClick={() => {
                            if (item.pdfId === null || item.pdfId == "") {
                              return;
                            }
                            const spaceId = activeSpaceId || 0;
                            dispatch(
                              setSelectedElementIdsBySpaceId({
                                spaceId: spaceId,
                                elementIds: [parseInt(item.pdfId, 10)],
                              })
                            );
                            if (item.bimId != null && item.bimId != "") {
                              const nameProperty: any = {};
                              nameProperty["element_name"] = item.bimEN;
                              const idProperty: any = {};
                              idProperty["element_id"] = item.bimId;
                              dispatch(
                                setBimProperties([nameProperty, idProperty])
                              );
                              dispatch(
                                setSelectedRoomBimId(
                                  parseInt(item.bimId, 10)
                                )
                              );
                            }
                          }}
                        >
                          定位
                        </Button>
                      </div>
                      <div className={styles.itemStyle}>
                        bimId:{item.bimId ? item.bimId : "无"}
                      </div>
                      <div
                        className={styles.itemStyle}
                        style={{
                          flex: 1.5,
                        }}
                      >
                        <Button
                          size="small"
                          className="storey-check-button"
                          onClick={() => {
                            if (item.bimId === null || item.bimId == "") {
                              return;
                            }
                            const nameProperty: any = {};
                            nameProperty["element_name"] = item.bimEN;
                            const idProperty: any = {};
                            idProperty["element_id"] = item.bimId;
                            dispatch(
                              setBimProperties([nameProperty, idProperty])
                            );
                            dispatch(
                              setSelectedRoomBimId(parseInt(item.bimId, 10))
                            );
                            if (item.pdfId != null && item.pdfId != "") {
                              const spaceId = activeSpaceId || 0;
                              dispatch(
                                setSelectedElementIdsBySpaceId({
                                  spaceId: spaceId,
                                  elementIds: [parseInt(item.pdfId, 10)],
                                })
                              );
                            }
                          }}
                        >
                          定位
                        </Button>
                      </div>
                    </div>
                  ) : null
                )
              : data["memberResults"].map((item: any, index) =>
                  item.elevation === elevation?.toString() &&
                  showMemberType(item.objectType, memberType) &&
                  showType(item.consistency, type) &&
                  showFilterPdfId(item.bimId, filterBimId) &&
                  showFilterPdfId(item.pdfId, filterPdfId) ? (
                    <div className={styles.storeyItem} key={index}>
                      <div
                        className={styles.itemStyle}
                        style={{
                          flex: 1,
                        }}
                      >
                        id:{item.resultId}
                      </div>
                      <div
                        className={styles.itemStyle}
                        style={{
                          color:
                            item.consistency == "Consistent" ? "green" : "red",
                        }}
                      >
                        {item.resultType == "boundingBox"
                          ? item.consistency == "Consistent"
                            ? "包围盒一致"
                            : "包围盒不一致"
                          : item.consistency == "Consistent"
                          ? "功能名一致"
                          : item.consistency == "PdfMissing"?
                            "图上名称缺失"
                          : item.resultType == "functionName"
                          ? item.consistency == "Consistent"
                            ? "功能名一致"
                            : "功能名不一致" 
                          : item.resultType == "familyName"?
                            item.consistency == "Consistent"
                            ? "构件类别一致"
                            : "构件类别不一致"
                          : "未知比对类型"
                          }
                      </div>
                      <div className={styles.itemStyle}>
                        {item.objectType === "memberDoor"
                          ? "门构件比对"
                          : item.objectType === "memberWindow"
                          ? "窗比对"
                          : "其他比对"}
                      </div>
                      <div
                        className={styles.itemStyle}
                        style={{
                          flex: 1,
                        }}
                      >
                        {item.elevation}层
                      </div>
                      <div className={styles.itemStyle}>pdfId:{item.pdfId}</div>
                      <div
                        className={styles.itemStyle}
                        style={{
                          flex: 1.5,
                        }}
                      >
                        <Button
                          size="small"
                          className="storey-check-button"
                          onClick={() => {
                            if (item.pdfId === null || item.pdfId == "") {
                              return;
                            }
                            const spaceId = activeSpaceId || 0;
                            dispatch(
                              setSelectedElementIdsBySpaceId({
                                spaceId: spaceId,
                                elementIds: [parseInt(item.pdfId, 10)],
                              })
                            );
                            if (item.bimId != null && item.bimId != "") {
                              const nameProperty: any = {};
                              nameProperty["element_name"] = item.bimEN;
                              const idProperty: any = {};
                              idProperty["element_id"] = item.bimId;
                              const fnProperty: any = {};
                              fnProperty["family_name"] = item.bimFN;
                              dispatch(
                                setBimProperties([nameProperty, idProperty, fnProperty])
                              );
                              dispatch(
                                setSelectedMemberBimId(parseInt(item.bimId, 10))
                              );
                            }
                          }}
                        >
                          定位
                        </Button>
                      </div>
                      <div className={styles.itemStyle}>
                        bimId:{item.bimId ? item.bimId : "无"}
                      </div>
                      <div
                        className={styles.itemStyle}
                        style={{
                          flex: 1.5,
                        }}
                      >
                        <Button
                          size="small"
                          className="storey-check-button"
                          onClick={() => {
                            if (item.bimId === null || item.bimId == "") {
                              return;
                            }
                            const nameProperty: any = {};
                            nameProperty["element_name"] = item.bimEN;
                            const idProperty: any = {};
                            idProperty["element_id"] = item.bimId;
                            const fnProperty: any = {};
                            fnProperty["family_name"] = item.bimFN;
                            dispatch(
                              setBimProperties([nameProperty, idProperty, fnProperty])
                            );
                            dispatch(
                              setSelectedMemberBimId(parseInt(item.bimId, 10))
                            );
                            if (item.pdfId != null && item.pdfId != "") {
                              const spaceId = activeSpaceId || 0;
                              dispatch(
                                setSelectedElementIdsBySpaceId({
                                  spaceId: spaceId,
                                  elementIds: [parseInt(item.pdfId, 10)],
                                })
                              );
                            }
                          }}
                        >
                          定位
                        </Button>
                      </div>
                    </div>
                  ) : null
                )}
          </div>
        )}
      </div>
      <Modal
        title="包围盒比对可视化"
        open={true}
        onOk={handleOk}
        onCancel={handleCancel}
        width={600}
        zIndex={isModalOpen ? 1000 : -1000}
        mask={false}
      >
        <div className={styles.modalContent} ref={modalRef}>
          <div className={styles.modalHead}>
            <div className={styles.modalTitle} style={{ color: "red" }}>
              PDF房间:红色
            </div>
            <div className={styles.modalTitle} style={{ color: "purple" }}>
              重合区域:紫色
            </div>
            <div className={styles.modalTitle} style={{ color: "blue" }}>
              BIM房间:蓝色
            </div>
          </div>
          <canvas ref={canvasRef}></canvas>
        </div>
      </Modal>
    </div>
  );
}

export default ResultList;
