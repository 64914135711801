import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { API_BASE_URL } from '../../config';
import { store } from '../../app/store';
import { Asset } from '../../interfaces';

class ConsistencyListService {
  private token: string | undefined;
  private client: AxiosInstance | undefined;
  init() {
    const curStore = store.getState();
    this.token = curStore?.auth?.token;

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: ''
    };
    if (this.token) {
      headers.Authorization = `Bearer ${this.token}`;
    }

    this.client = axios.create({
      baseURL: `${API_BASE_URL}`,
      headers: headers,
    });
    return this.client;
  }

  async getConsistencyProjects() {
    return this.init().get('/consistency', {
    });
  }

  async createConsistencyProject(name: string) {
    return this.init().post('/consistency', {
      name
    });
  }

  async deleteConsistencyProject(projectId: string) {
    return this.init().delete(`/consistency/${projectId}`);
  }

  /**
   * 获取项目完整的资源列表
   * @param projectId 项目ID
   */
  async getAssetsByProjectId(projectId: number): Promise<AxiosResponse<Asset[]>> {
    return this.init().get('/asset', {
      params: {
        projectId
      },
    });
  }

  /**
   * 获取项目完整的空间列表
   * @param projectId 项目ID
   */
  async getProjectDetail(projectId: number) {
    return this.init().get(`/${projectId}/project_detail`);
  }
}

export default new ConsistencyListService();
