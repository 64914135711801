import { FileOutlined } from '@ant-design/icons';
import { Menu, Tooltip } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import styles from './LibraryTree.module.less';

interface LibraryTreeProps {
  snlList: string[];
}

function LibraryTree(props: LibraryTreeProps) {
  const {snlList} = props;
  const parseSnlName = (snlName: string) => {
    let code = '';
    let rule_name = '';

    if (snlName.includes('《')) {
      [code, rule_name] = snlName.split('《');
      rule_name = `《${rule_name}`;
    } else if (snlName.includes('/')) {
      [code, rule_name] = snlName.split('/');
    } else {
      code = snlName;
    }
    return { code, rule_name };
  };
  
  const buildMenu = (snlList: string[]) => {
    return snlList.map(snlName => {
      const name = snlName.replace('.snl', '');
      const { code, rule_name } = parseSnlName(name);
      return ({
        key: name,
        label: (
          <Tooltip title={snlName}>
            <Link to={`/snl/${name}`} className={styles.menuItem}>
              <div className={styles.code}>{code}</div>
              <div>{rule_name}</div>
            </Link>
          </Tooltip>
        ),
        icon: <FileOutlined/>,
        // children: [],
        // type: 'group',
      });
    });
  };

  return (
    <div className={styles.root}>
      <Menu className={styles.resultWrapper}
        mode="inline"
        items={buildMenu(snlList)}
      />
    </div>
  )
}

export default LibraryTree;
