import React, { useEffect, useState } from 'react';
import './App.less';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TuzhiVis from './modules/tuzhi-vis/TuzhiVis';
import NavBar from './modules/navbar/NavBar';
import ProjectList from './modules/project-list/ProjectList';
import UserLogin from './modules/auth/UserLogin';
import AuthRoute from './modules/auth/AuthRoute';
import Portal from './modules/portal/Portal';
import SNLViewer from './modules/snl-viewer/SNLViewer';
import ConsistencyList from './modules/consistency-list/ConsistencyList';
import ConsistencyViewer from './modules/consistency-viewer/ConsistencyViewer';
import TianhuaConsistencyViewer from './modules/tianhua-consistency-viewer/TianhuaConsistencyViewer';
import TianhuaConsistencyList from './modules/tianhua-consistency-list/TianhuaConsistencyList';
import { useAppDispatch } from './app/hooks';
import { fetchVersion } from './appSlice';
import Unauthorized from './modules/auth/Unauthorized';
import AuthService from './modules/auth/Auth.service';
import { setUser } from './modules/auth/authSlice';

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchVersion());
  }, []);


  const [isAutoLoggedIn, setIsAutoLoggedIn] = useState(false);

  useEffect(() => {
    // 深圳消防项目，关闭认证功能，使用默认密码进行自动登录，以便正确设置token
    if (process.env.REACT_APP_THEME === 'szzj') {
      AuthService
        .login('szzj-xiaofang', '233233')
        .then(res => {
          dispatch(setUser(res.data));
          setIsAutoLoggedIn(true);
        })
        .catch(res => {
          console.log('err', res);
        })
    }
  }, []);

  if (process.env.REACT_APP_THEME === 'szzj' && !isAutoLoggedIn) {
    return <div>自动登录中...</div>;
  }

  return (
    <div className="App">
      <Router basename={process.env.PUBLIC_URL || '/'}>
        <NavBar />
        <Routes>
          <Route
            path="/snl/*"
            element={(
              <AuthRoute
                type="private"
                limitedRole='UserRole.TRIAL_USER'
                outlet={(
                  <SNLViewer temp={'3434'}/>
                )}
              />
            )}
          />
          <Route
            path="/project/:username/:projectId/:projectName"
            element={(
              <AuthRoute
              type="private"
              outlet={(
                <TuzhiVis />
              )}
              />
            )}
          />
          <Route
            path="/"
            element={(
              <Portal />
            )}
          />
          <Route
            path="/login"
            element={(
              <UserLogin />
            )}
          />
          <Route
            path="/projects"
            element={(
              <AuthRoute
                type="private"
                outlet={(
                  <ProjectList />
                )}
              />
            )}
          />
          <Route
            path="/recyclebin"
            element={(
              <AuthRoute
                type="private"
                outlet={(
                  <ProjectList recycleBin />
                )}
              />
            )}
          />
          <Route
            path="/consistencys"
            element={(
              <ConsistencyList username="test" />
            )}
          />
          <Route
            path="/tianhua_consistencys"
            element={(
              <TianhuaConsistencyList username="test" />
            )}
          />
          <Route
            path="/consistency/:projectName/:projectId"
            element={(
              <AuthRoute
              type="private"
              outlet={(
                <ConsistencyViewer username="test" projectId={0} />
              )}
              />
            )}
          />
          <Route
            path="/tianhua_consistency/:projectName/:projectId"
            element={(
              <AuthRoute
              type="private"
              outlet={(
                <TianhuaConsistencyViewer username="test" projectId={0} />
              )}
              />
            )}
          />
          <Route path="/unauthorized" element={<Unauthorized />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
