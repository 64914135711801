import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { API_BASE_URL } from '../../config';
import { store } from '../../app/store';
import { Asset, ElementRelation, Geom, SpaceRelation } from '../../interfaces';

class TuzhiVisService {
  private token: string | undefined;
  private client: AxiosInstance | undefined;
  init() {
    const curStore = store.getState();
    this.token = curStore?.auth?.token;

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: ''
    };
    if (this.token) {
      headers.Authorization = `Bearer ${this.token}`;
    }

    this.client = axios.create({
      baseURL: `${API_BASE_URL}`,
      headers: headers,
    });
    return this.client;
  }

  /**
   * 获取项目信息
   */
  async getProject(projectId: number) {
    return this.init().get(`/project/${projectId}`);
  }

  /**
   * 获取项目完整的资源列表
   * @param projectId 项目ID
   */
  async getAssetsByProjectId(projectId: number): Promise<AxiosResponse<Asset[]>> {
    return this.init().get('/asset', {
      params: {
        projectId
      },
    });
  }

  /**
   * 获取项目完整的空间列表
   * @param projectId 项目ID
   */
  async getSpacesByProjectId(projectId: number) {
    return this.init().get('/space', {
      params: {
        projectId
      },
    });
  }

  /**
   * 一键识别整个项目
   * @param projectId 项目ID
   */
  async recognizeProject(projectId: number) {
    return this.init().put(`/project/${projectId}/process`);
  }

  /**
   * 一键重识别项目
   * @param projectId 项目ID
   */
  async rerunProject(projectId: number) {
    return this.init().put(`/project/${projectId}/rerun`);
  }

  /**
   * 提交识别原始资源
   * @param assetId 资源ID
   */
  async recognizeOriginAsset(assetId: number) {
    return this.init().put(`/asset/${assetId}/processOrigin`);
  }

  /**
   * 获取项目中所有插件信息
   * @param projectId 项目ID
   */
  async getPlugins(projectId: number) {
    return this.init().get(`/project/${projectId}/plugins`);
  }

  /**
   * 获取空间的BCJSON文件
   * @param spaceId 空间ID
   */
  async getBCJSONBySpaceId(spaceId: number) {
    return this.init().get(`/space/${spaceId}/bcjson`, {
      responseType: 'blob',
    });
  }

  /** 通用的数据导出接口，通过给定的数据类型导出数据
   * @param spaceId 空间ID
   * @param exportType 数据类型
   */
  async exportDataBySpaceId(spaceId: number, exportType: string) {
    return this.init().get(`/space/${spaceId}/export/${exportType}`, {
      responseType: 'blob',
    });
  }

  /**
   * 获取空间的IFC文件
   */
  async getIFCBySpaceId(spaceId: number) {
    return this.init().get(`/space/${spaceId}/ifc`, {
      responseType: 'blob',
    });
  }

  /**
   * 刷新IFC文件
   */
  async refreshIFCBySpaceId(spaceId: number) {
    return this.init().post(`/space/${spaceId}/ifc/refresh`);
  }

  /**
   * 修改构件属性值
   */
  async updateElementProperty(elementId: number, propertyKey: string, value: string) {
    return this.init().put(`/element/${elementId}/property/${propertyKey}`, {
      value,
    })
  }

  /**
   * 修改空间属性值
   */
  async updateSpaceProperty(spaceId: number, propertyKey: string, value: string) {
    return this.init().put(`/space/${spaceId}/property/${propertyKey}`, {
      value,
    })
  }

  /**
    * 删除构件关系
    */
  async deleteElementRelation(elementFromId: number, elementToId: number, relType: string) {
    return this.init().delete(`/element_relation/${elementFromId}/toId/${elementToId}/relType/${relType}`);

  }
  /**
    * 新增构件关系
    */
  async addElementRelation(elementFromId: number, elementToId: number, relType: string) {
    return this.init().post(`/element_relation/${elementFromId}/toId/${elementToId}/relType/${relType}`);

  }

  /**
   * 修改项目属性值
   */
  async updateProjectProperty(projectId: number, propertyKey: string, value: string) {
    return this.init().put(`/project/${projectId}/property/${propertyKey}`, {
      value,
    });
  }

  /**
   * 获取空间空间关系
   */
  async getSpaceRelationsByProjectId(projectId: number): Promise<AxiosResponse<SpaceRelation[]>> {
    return this.init().get(`/spaceRelation/`, {
      params: {
        projectId,
      }
    });
  }

  /**
   * 获取构件的全部几何信息
   * @param elementId
   */
  async getElementGeometries(elementId: number): Promise<AxiosResponse<Geom[]>> {
    return this.init().get(`/element/${elementId}/geoms`);
  }

  /**
   * 下载项目的原始压缩包
   * @param projectId
   */
  async downloadOriginZip(projectId: number) {
    return this.init().get(`/project/${projectId}/originZip`, {
      responseType: 'blob',
    });
  }

  /**
   * 仅开始切分图纸任务
   * @param projectId
   */
  async splitProjectFiles(projectId: number) {
    return this.init().put(`/project/${projectId}/split`);
  }

  /**
   * 停止识别项目未完成任务
   */
  async revokeProjectPendingTasks(projectId: number) {
    return this.init().put(`/project/${projectId}/revokePending`);
  }
}

export default new TuzhiVisService();
