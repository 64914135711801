import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  Asset,
} from '../../interfaces';

import { fromPairs, isEqual, merge, uniqWith } from 'lodash';

export interface ConsistencyState {
  // 存储相关数据
  projectId: number | null; // 本项目信息
  elevation: string | null; // 当前楼层数
  tuzhiProjectId: number | null; // 对应图智项目ID
  uploadIfc: boolean | null; // 是否上传IFC
  selectedFragmentId: number | null; // 选中的FragmentId
  bimProperties: any | null; // BIM属性
  selectedRoomBimId: number | null; // 选中的房间BIM ID
  selectedMemberBimId: number | null; // 选中的构件BIM ID
  tuzhiSpaceId: number | null; // 对应图智空间ID
}

const initialState: ConsistencyState = {
  projectId: null,
  elevation: null,
  tuzhiProjectId: null,
  uploadIfc: null,
  selectedFragmentId: null,
  bimProperties: null,
  selectedRoomBimId: null,
  selectedMemberBimId: null,
  tuzhiSpaceId: null,
};


export const consistencySlice = createSlice({
  name: 'consistency',
  initialState,
  reducers: {
    setProjectId: (state, action) => {
      state.projectId = action.payload;
    }, // Add a comma here
    setElevation: (state, action) => {
      state.elevation = action.payload;
    },
    setTuZhiProjectId: (state, action) => {
      state.tuzhiProjectId = action.payload;
    },
    setUploadIfc: (state, action) => {
      state.uploadIfc = action.payload;
    },
    setSelectedFragmentId: (state, action) => {
      state.selectedFragmentId = action.payload;
    },
    setBimProperties: (state, action) => {
      state.bimProperties = action.payload;
    },
    setSelectedRoomBimId: (state, action) => {
      state.selectedRoomBimId = action.payload;
    },
    setSelectedMemberBimId: (state, action) => {
      state.selectedMemberBimId = action.payload;
    },
    setTuZhiSpaceId: (state, action) => {
      state.tuzhiSpaceId = action.payload;
    },
    clearConsistencyCache: () => initialState,
  },
});

export const {
  setProjectId,
  setElevation,
  setTuZhiProjectId,
  setUploadIfc,
  setSelectedFragmentId,
  setBimProperties,
  setSelectedRoomBimId,
  setTuZhiSpaceId,
  setSelectedMemberBimId,
  clearConsistencyCache
} = consistencySlice.actions;

export const selectProjectId = (state: RootState) => state.consistency.projectId;
export const selectElevation = (state: RootState) => state.consistency.elevation;
export const selectTuZhiProjectId = (state: RootState) => state.consistency.tuzhiProjectId;
export const selectUploadIfc = (state: RootState) => state.consistency.uploadIfc;
export const selectSelectedFragmentId = (state: RootState) => state.consistency.selectedFragmentId;
export const selectBimProperties = (state: RootState) => state.consistency.bimProperties;
export const selectSelectedRoomBimId = (state: RootState) => state.consistency.selectedRoomBimId;
export const selectTuZhiSpaceId = (state: RootState) => state.consistency.tuzhiSpaceId;
export const selectSelectedMemberBimId = (state: RootState) => state.consistency.selectedMemberBimId;


export default consistencySlice.reducer;
